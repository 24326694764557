export const localFeatureIds = [
  //
  'export video',
  'ss onboarding',
  'smartlook-canvas',
  'viz-options',
  'magic-paint',
  'rich-color-filter',
  'mobile-viz',
  'fp-add-room',
  'professional-trial-14-days',
  'onboarding-pro-schedule',
  'super-pricing-model-v2',
  'template-assistant',
  'sourcing-assistant',
  'design-assistant',
  'signup-check-email-flow',
  'signup-google-button',
  'template-assistant-no-chat',
  'template-assistant-skip-replace',
] as const

export const externalFeatureIds = [
  //
  'smartlook-canvas',
  'magic-paint',
  'rich-color-filter',
  'mobile-viz',
  'professional-trial-14-days',
  'onboarding-pro-schedule',
  'super-pricing-model-v2',
  'template-assistant',
  'sourcing-assistant',
  'design-assistant',
  'signup-check-email-flow',
  'signup-google-button',
  'template-assistant-no-chat',
  'template-assistant-skip-replace',
] as const

export type LocalFeatureId = (typeof localFeatureIds)[number]
export type ExternalFeatureId = (typeof externalFeatureIds)[number]
export type FeatureId = LocalFeatureId | ExternalFeatureId

export function isLocalFeature(
  featureId: FeatureId,
): featureId is LocalFeatureId {
  return localFeatureIds.includes(featureId as LocalFeatureId)
}

export function isExternalFeature(
  featureId: FeatureId,
): featureId is ExternalFeatureId {
  return externalFeatureIds.includes(featureId as ExternalFeatureId)
}
