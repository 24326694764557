import { computed, inject, reactive, toRefs } from 'vue'
import { identify as trackingIdentify } from '/@/core/tracking/api'
import { type CurrentUser } from './types'
import { useRollbar } from '/@/core/rollbar'
import { clearExperiments } from '/@/core/experiment'
import { identifyBeacon } from '/@/utils/helpscout'
import { DefaultApolloClient, useQuery } from '/@/core/graphql'
import { ApolloClient, gql } from '@apollo/client/core'
import { type NormalizedCacheObject } from '@apollo/client/cache'

interface State {
  accessToken: string | null
  loginIdentifier: string | null
  current: CurrentUser | null
}

function _useUserData() {
  // FIXME: we may avoid doing the type cast if we create an InjectKey type for the apollo client
  // dependency somewhere in the main.ts
  const client = inject(
    DefaultApolloClient,
  ) as ApolloClient<NormalizedCacheObject>

  // current user
  async function fetch(): Promise<CurrentUser | null> {
    // NOTE: using plain apollo client here instead of useQuery hooks as we don't want the response
    // data to be buried in reactive wrappers. This data will later be incorporated in a reactive
    // state object, so this step here is superfluos.
    try {
      const { data, loading } = await client.query({
        query: gql`
          query getUser {
            user {
              id
              email
              firstName
              lastName
              isAdmin
              designerId
              designer {
                id
                status
                nickname
                branding {
                  backgroundColor
                  logoImageUrl
                  primaryColor
                  secondaryColor
                }
              }
              clientId
            }
          }
        `,
        fetchPolicy: 'network-only',
      })

      if (loading || !data) return null

      return data.user
    } catch {
      return null
    }
  }

  return { fetch }
}

// NOTE: this is the data module factory
function create() {
  const userData = _useUserData()
  const rollbar = useRollbar()

  // The main state is a reactive object
  const state = reactive<State>({
    accessToken: null,
    loginIdentifier: null,
    current: null,
  })

  // we can add computed properties that run every time a state property used in the callback
  // changes
  const role = computed(() => {
    if (state.current?.isAdmin) return 'admin'
    if (!state.current) return 'anon'

    // TODO: role check based on profile
    return 'user'
  })

  function setAccessToken(accessToken: string) {
    state.accessToken = accessToken
  }

  function setLoginIdentifier(loginIdentifier: string) {
    state.loginIdentifier = loginIdentifier
  }

  const isLoggedIn = computed(() => {
    return Boolean(state.accessToken)
  })

  function clear() {
    state.current = null
    state.accessToken = null
    clearExperiments()
  }

  async function refreshCurrentUser() {
    await fetchCurrentUser()
  }

  async function fetchCurrentUser() {
    const user = await userData.fetch()

    if (user) {
      trackingIdentify(user)
      rollbar.configure(user)
      identifyBeacon(user)
    }

    state.current = user
  }

  return reactive({
    // NOTE: we explode the reactive object into singular refs, so that we can rewrap everything
    // into a new reactive object incorporating computed functions, and other functions
    ...toRefs(state),
    role,
    isLoggedIn,
    setAccessToken,
    setLoginIdentifier,
    fetchCurrentUser,
    refreshCurrentUser,
    clear,
  })
}

let cachedApi: ReturnType<typeof create>

export function useUser() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}

export function useUserEmailVerified() {
  const { result, loading, refetch } = useQuery<{
    user?: {
      isEmailVerified?: boolean
    }
  }>(gql`
    query getUserIsEmailVerified {
      user {
        isEmailVerified
      }
    }
  `)

  const isEmailVerified = computed(() => result.value?.user?.isEmailVerified)

  return { isEmailVerified, loading, refetch }
}
