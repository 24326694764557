import { gql } from '@apollo/client/core'

export const mockupAccessFragment = gql`
  fragment MockupAccessProps on MockupAccess {
    role
    canEdit
    canEditNote
    canEditSettings
    canPurchasePowerups
    canDelete
  }
`
