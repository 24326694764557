import { gql } from '@apollo/client/core'

export const paletteAccessFragment = gql`
  fragment PaletteAccessProps on PaletteAccess {
    role
    canEdit
    canEditSettings
    canDelete
  }
`
