import { type SpoakSignType } from '/@/common/SpoakSign/types'
import {
  type ProjectAccess,
  type ProjectInfo,
  type ProjectStatus,
  type ProjectType,
} from '/@/common/projects/types'
import { type Follows } from '/@/common/follow/types'
import {
  type Color,
  type MockupAccess,
  type PaletteAccess,
} from '/@/common/things/types'
import { type ClientWithInvoices } from '/@/common/clients/types'
import { type MockupType } from '/@/common/viz/types'

type LegacyDesignerTier = 'DIY' | 'GROWTH' | 'DESIGNER'
export type NewDesignerTier = 'PLAY' | 'PLUS' | 'PREMIUM'
export type PricingDesignerTier =
  | 'INDIVIDUAL_1'
  | 'INDIVIDUAL_1_1'
  | 'INDIVIDUAL_1_5'
  | 'INDIVIDUAL_2'
  | 'PROFESSIONAL_1'
  | 'PROFESSIONAL_2'
  | 'SUPER_1'
export type DesignerTier =
  | 'FREE'
  | NewDesignerTier
  | LegacyDesignerTier
  | PricingDesignerTier

export type DesignerStatus = 'ACTIVE' | 'INACTIVE' | 'HOLD'
export type DesignerType = 'ACTIVE' | 'TEST'

export type ShopSetupStatus = 'INACTIVE' | 'IN_PROGRESS' | 'ACTIVE'
export type ShopVisibility = 'VISIBLE' | 'HIDDEN'

export type OnboardingState = 'FREE' | 'COMPLETE'

export type DesignerPersona =
  | 'PROFESSIONAL'
  | 'PART_TIME'
  | 'HOBBY'
  | 'SPECIFIC_SPACE'
export const professionalPersonas: DesignerPersona[] = [
  'PART_TIME',
  'PROFESSIONAL',
]

export type DesignerTeamType = 'SINGLE' | 'FIRM' | 'BRAND'
export type DesignerTeamScale = 'SINGLE' | 'SMALL' | 'MEDIUM' | 'LARGE'

export type SubscriptionInterval = 'MONTH' | 'YEAR'

export const allTiers: DesignerTier[] = [
  'FREE',
  'DIY',
  'GROWTH',
  'DESIGNER',
  'PLAY',
  'PLUS',
  'PREMIUM',
  'INDIVIDUAL_1',
  'INDIVIDUAL_1_1',
  'INDIVIDUAL_1_5',
  'INDIVIDUAL_2',
  'PROFESSIONAL_1',
  'PROFESSIONAL_2',
  'SUPER_1',
]

export const paidTiers: DesignerTier[] = allTiers.filter(t => t !== 'FREE')
export const paidNonIndividual1Tiers = paidTiers.filter(
  t => t !== 'INDIVIDUAL_1' && t !== 'INDIVIDUAL_1_1',
)
export const paidBelowIndividual2Tiers = paidTiers.filter(
  t => t !== 'INDIVIDUAL_1' && t !== 'INDIVIDUAL_1_1' && t !== 'INDIVIDUAL_1_5',
)

export const currentTiers: DesignerTier[] = [
  'INDIVIDUAL_1',
  'INDIVIDUAL_1_1',
  'INDIVIDUAL_1_5',
  'INDIVIDUAL_2',
  'PROFESSIONAL_1',
  'PROFESSIONAL_2',
  'SUPER_1',
]

export const professionalTiers: DesignerTier[] = [
  'PLUS',
  'PREMIUM',
  'PROFESSIONAL_1',
  'PROFESSIONAL_2',
  'SUPER_1',
]

export type OrganizationType = 'SCHOOL' | 'ENTERPRISE'

export type DesignerCancelType = 'PAUSE' | 'CANCEL'

export interface Branding {
  logoImageUrl: string | null
  primaryColor: string | null
  secondaryColor: string | null
  backgroundColor: string | null
}

export interface ContentDesigner {
  id: string
  nickname: string
  website?: string
  branding: Branding
}

export interface ShortDesignerInfo {
  id: string
  firstName: string
  lastName: string
  nickname: string
  imageUrl?: string
}

export interface ShortProjectInfo {
  id: string
  name: string
  access: ProjectAccess
  permissions?: {
    designer: ShortDesignerInfo
  }[]
  projectType: ProjectType
  status: ProjectStatus
}

export interface ShortViziInfo {
  id: string
  name: string
  imageUrl: string
  designerId?: string
  designer?: ShortDesignerInfo
  projectId?: string
  project?: ShortProjectInfo
  access: MockupAccess
  updatedAt?: string
  mockupType: MockupType
  vizVersion?: string
  permissions?: {
    designer: ShortDesignerInfo
  }[]
}

export interface ShortPaletteInfo {
  id: string
  name: string
  colors: Color[]
  designerId?: string
  designer?: ShortDesignerInfo
  projectId?: string
  project?: ShortProjectInfo
  access: PaletteAccess
  updatedAt?: string
  permissions?: {
    designer: ShortDesignerInfo
  }[]
}

export type InterestType =
  | 'PRODUCTS'
  | 'MOCKUPS'
  | 'INSPIRATION'
  | 'PROJECTS'
  | 'COMMUNITY'
  | 'SKILLS'
  | 'CLIENTS'
  | 'CHALLENGES'
  | 'CONTENT'
  | 'BUSINESS'
  | 'FUN'
  | 'VISUALIZE'
  | 'DISCOUNTS'
  | 'OTHER'
  | 'DIY'
  | 'DECOR'
  | 'LAYOUT'
  | 'FASHION'
  | 'EVENTS'

export interface DesignerInterestInfo {
  interestType: InterestType
  custom: string
}

export interface DesignerLocation {
  city?: string
  state?: string
  country: string
}

export interface Organization {
  id: string
  name: string
  type: OrganizationType
}

export interface DesignerGroupMember {
  id: string
  designer: ShortDesignerInfo
}

export interface DesignerGroup {
  id: string
  organization?: Organization
  designers?: DesignerGroupMember[]
}

export type DesignerSituation =
  | 'MOVING_CURRENTLY'
  | 'MOVING_SOON'
  | 'HOME_SETTLED'
export type DesignerHousingType = 'RENT' | 'OWN'

export interface CurrentDesigner {
  id: string
  firstName: string
  lastName: string
  nickname: string
  currentProject?: ProjectInfo
  automaticProject?: ProjectInfo
  imageUrl?: string
  hometown?: string
  location?: DesignerLocation
  birthday?: string
  bio?: string
  persona: DesignerPersona
  teamType?: DesignerTeamType
  teamScale?: DesignerTeamScale
  situation?: DesignerSituation
  housing: DesignerHousingType
  interests: DesignerInterestInfo[]
  tier: DesignerTier
  isVerified?: boolean
  onboardingState?: OnboardingState
  referralCode?: string
  branding: Branding
  status: DesignerStatus
  portfolioProjects: Array<{
    id: string
    projectId: string
  }>
  follows: Follows
  sign?: SpoakSignType
  instagramHandle?: string
  pinterestHandle?: string
  tiktokHandle?: string
  website?: string
  shopVisibility: ShopVisibility
  shopSetupStatus?: ShopSetupStatus
  shopBalance?: {
    available: string
    availableCents: number
    pending: string
    pendingCents: number
  }
  invoices: Array<{
    id: string
  }>
  clients?: ClientWithInvoices[]
  membershipCardUrl?: string
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  subscriptionInterval?: SubscriptionInterval
  designerType: DesignerType
  activeSince: string
  groups: DesignerGroup[]
  freeTierV1Experiment?: boolean
}
