import { type ComputedRef, type Ref, ref, watchEffect, computed } from 'vue'
import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '/@/core/graphql'
import { type ProjectPermissionRole } from '/@/common/projects/types'
import { addListItemToCache, deleteListItemFromCache } from '/@/utils/apollo'
import { type Project } from '/@/common/projects/types'
import { useCurrentDesigner } from '/@/data/designer/current'

// types
type ProjectPermissionInput = {
  designerId: string
  projectPermissionRole: ProjectPermissionRole
}

type ProjectPermissionInviteInput = {
  emailAddress: string
  projectPermissionRole: ProjectPermissionRole
  firstName: string
  lastName: string
}

// fragments
export const projectPermissionFragment = gql`
  fragment ProjectPermissionProps on ProjectPermission {
    id
    projectPermissionRole
    projectPermissionType
    visibility
    designer {
      id
      firstName
      lastName
      nickname
      onboardingState
      imageUrl
    }
  }
`

// api
export function useProjectPermissionApi() {
  // create permission
  const CREATE_PROJECT_PERMISSION_MUTATION = gql`
    ${projectPermissionFragment}
    mutation createProjectPermission(
      $projectId: String!
      $input: ProjectPermissionInput!
    ) {
      project {
        permissions {
          create(projectId: $projectId, input: $input) {
            status
            recordId
            record {
              ...ProjectPermissionProps
            }
          }
        }
      }
    }
  `
  const { mutate: createProjectPermissionMutate } = useMutation(
    CREATE_PROJECT_PERMISSION_MUTATION,
  )
  async function createProjectPermission(
    project: ComputedRef<Project | undefined>,
    designerId: string,
    role: ProjectPermissionRole,
  ) {
    if (!project.value) return
    const projectId = project.value.id
    const input: ProjectPermissionInput = {
      designerId,
      projectPermissionRole: role,
    }
    await createProjectPermissionMutate(
      { projectId, input },
      {
        update(
          cache,
          {
            data: {
              project: {
                permissions: {
                  create: { record },
                },
              },
            },
          },
        ) {
          addListItemToCache(
            cache.identify({ ...project.value }),
            cache,
            'permissions',
            record,
          )
        },
      },
    )
  }

  // permission invite mutation
  const PROJECT_PERMISSION_INVITE_MUTATION = gql`
    ${projectPermissionFragment}
    mutation projectPermissionInvite(
      $projectId: String!
      $input: ProjectPermissionInviteInput!
    ) {
      project {
        permissions {
          invite(projectId: $projectId, input: $input) {
            status
            recordId
            record {
              ...ProjectPermissionProps
            }
          }
        }
      }
    }
  `
  const { mutate: projectPermissionInviteMutate } = useMutation(
    PROJECT_PERMISSION_INVITE_MUTATION,
  )
  async function projectPermissionInvite(
    project: ComputedRef<Project | undefined>,
    emailAddress: string,
    role: ProjectPermissionRole,
    firstName: string,
    lastName: string,
  ) {
    if (!project.value) return
    const projectId = project.value.id
    const input: ProjectPermissionInviteInput = {
      emailAddress,
      projectPermissionRole: role,
      firstName,
      lastName,
    }
    await projectPermissionInviteMutate(
      { projectId, input },
      {
        update(
          cache,
          {
            data: {
              project: {
                permissions: {
                  invite: { record },
                },
              },
            },
          },
        ) {
          addListItemToCache(
            cache.identify({ ...project.value }),
            cache,
            'permissions',
            record,
          )
        },
      },
    )
  }

  // delete permission
  const DELETE_PROJECT_PERMISSION = gql`
    ${projectPermissionFragment}
    mutation deleteProjectPermission($id: String!) {
      project {
        permissions {
          delete(id: $id) {
            status
            recordId
            record {
              ...ProjectPermissionProps
            }
          }
        }
      }
    }
  `
  const { mutate: deleteProjectPermissionMutate } = useMutation(
    DELETE_PROJECT_PERMISSION,
  )
  async function deleteProjectPermission(
    project: ComputedRef<Project | undefined>,
    projectPermissionId: string,
  ) {
    await deleteProjectPermissionMutate(
      { id: projectPermissionId },
      {
        update(
          cache,
          {
            data: {
              project: {
                permissions: {
                  delete: { recordId },
                },
              },
            },
          },
        ) {
          if (!project.value) return
          deleteListItemFromCache(
            cache.identify({ ...project.value }),
            cache,
            'permissions',
            recordId,
          )
        },
      },
    )
  }
  return {
    createProjectPermission,
    projectPermissionInvite,
    deleteProjectPermission,
  }
}

export type DesignerContact = {
  id: string
  emailAddress: string
  designer: {
    id: string
    firstName: string
    lastName: string
    imageUrl: string
  }
}

type DesignerContactResponse = {
  contacts: DesignerContact[]
}

export function useDesignerContacts() {
  // get permissioned designers
  const GET_DESIGNER_CONTACTS = gql`
    query getDesignerContacts {
      contacts {
        id
        emailAddress
        designer {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  `
  const {
    result: designerContactsResponse,
    loading,
    error,
    refetch,
  } = useQuery<DesignerContactResponse>(GET_DESIGNER_CONTACTS)

  const contacts = computed(() => designerContactsResponse.value?.contacts)

  return { contacts, loading, error, refetch }
}

export function useProjectRelationship(
  project: ComputedRef<Project | undefined>,
  loadingProject?: Ref<boolean>,
) {
  const relationship = ref<'OWNER' | 'CLIENT' | 'COLLABORATOR' | null>(null)
  const loading = ref<boolean>(true)
  const { designer, loading: loadingDesigner } = useCurrentDesigner()
  function getRelationship() {
    if (loadingDesigner.value || loadingProject?.value) {
      relationship.value = null
      loading.value = true
      return
    }
    if (!designer.value || !project.value) {
      relationship.value = null
      loading.value = false
      return
    }
    if (project.value?.designer?.id === designer.value?.id) {
      relationship.value = 'OWNER'
      loading.value = false
      return
    }
    const permission = project.value.permissions.find(perm => {
      return perm.designer.id === designer.value?.id
    })
    if (permission?.projectPermissionRole) {
      relationship.value = permission.projectPermissionRole
      loading.value = false
    } else {
      relationship.value = null
      loading.value = false
    }
  }
  watchEffect(getRelationship)
  return { relationship, loading }
}
