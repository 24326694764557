import { computed } from 'vue'
import { useQuery } from '/@/core/graphql'
import {
  paidTiers,
  currentTiers,
  professionalTiers,
  type CurrentDesigner,
  type DesignerTier,
} from '/@/data/designer/types'
import type { StripeCustomer } from '/@/common/accounts/types'
import { type GoalGroup } from '/@/common/school/types'
import { gql } from '@apollo/client/core'
import { designerCurrentProjectFragment } from '../fragments'
import { stripeCustomerFragment } from '/@/common/accounts/fragments'
import { useRoute } from 'vue-router'
import { useConfig } from '/@/core/config'
import { useTermsModal } from '/@/common/modals/TermsModal'

interface Response {
  user?: {
    designer: CurrentDesigner
  }
}
export function useCurrentDesigner() {
  const { result, loading, refetch } = useQuery<Response>(gql`
    ${designerCurrentProjectFragment}
    query getCurrentDesigner {
      user {
        id
        designer {
          id
          sign
          imageUrl
          firstName
          lastName
          hometown
          location {
            city
            state
            country
          }
          birthday
          bio
          persona
          teamType
          teamScale
          situation
          housing
          interests {
            id
            interestType
            custom
          }
          currentProject {
            ...DesignerCurrentProjectProps
          }
          automaticProject {
            ...DesignerCurrentProjectProps
          }
          portfolioProjects {
            id
            projectId
          }
          isVerified
          nickname
          referralCode
          tier
          onboardingState
          branding {
            primaryColor
            secondaryColor
            backgroundColor
            logoImageUrl
          }
          status
          follows {
            followers {
              count
            }
            following {
              count
              list {
                id
              }
            }
          }
          instagramHandle
          pinterestHandle
          tiktokHandle
          website
          shopVisibility
          shopSetupStatus
          shopBalance {
            available
            availableCents
            pending
            pendingCents
          }
          invoices {
            id
          }
          membershipCardUrl
          stripeSubscriptionId
          stripeCustomerId
          subscriptionInterval
          designerType
          activeSince
          groups {
            id
            organization {
              id
              name
              type
            }
            designers {
              id
              designer {
                id
                firstName
                lastName
                nickname
                imageUrl
                sign
              }
            }
          }
          freeTierV1Experiment
        }
      }
    }
  `)

  const designer = computed(() => {
    return result.value?.user?.designer
  })

  function hasAccessToGoal(goalGroup?: GoalGroup) {
    if (!designer.value) return false

    const designerTier = designer.value.tier

    if (goalGroup === 'DESIGN') {
      return paidTiers.includes(designerTier)
    } else {
      const invalidTiers = new Set<DesignerTier>([
        'DIY',
        'PLAY',
        'INDIVIDUAL_1',
        'INDIVIDUAL_1_1',
        'INDIVIDUAL_1_5',
        'INDIVIDUAL_2',
      ])
      const validTiers = paidTiers.filter(t => !invalidTiers.has(t))
      return validTiers.includes(designerTier)
    }
  }

  const route = useRoute()
  const config = useConfig()
  const termsModal = useTermsModal()
  function shouldSetupShop() {
    if (designer.value?.shopSetupStatus === 'ACTIVE') return false

    termsModal.show({
      contentMode: 'html',
      async loadContent() {
        const { content } = await import('/@/data/shop-tc')
        return content
      },
      async onAccept() {
        window.location.href = config.getShopOnboardingLink(route.fullPath)
      },
    })

    return true
  }

  const organizations = computed(() =>
    designer.value?.groups.flatMap(g =>
      g.organization ? [g.organization] : [],
    ),
  )

  const isOrgMember = computed(() => Boolean(organizations.value?.length))

  const isSchoolMember = computed(() =>
    organizations.value?.some(o => o.type === 'SCHOOL'),
  )

  const isEnterpriseMember = computed(() =>
    organizations.value?.some(o => o.type === 'ENTERPRISE'),
  )

  const groupMembers = computed(
    () =>
      designer.value?.groups.flatMap(g => {
        if (g.organization?.type === 'SCHOOL') return []
        else return g.designers ?? []
      }) ?? [],
  )

  const isProfessional = computed(
    () =>
      designer.value?.tier && professionalTiers.includes(designer.value.tier),
  )

  const isPaying = computed(
    () =>
      designer.value?.status === 'ACTIVE' &&
      designer.value?.stripeSubscriptionId &&
      designer.value?.tier !== 'FREE',
  )

  const isLegacyTier = computed(
    () => designer.value?.tier && !currentTiers.includes(designer.value.tier),
  )

  return {
    designer,
    loading,
    hasAccessToGoal,
    shouldSetupShop,
    organizations,
    isOrgMember,
    isEnterpriseMember,
    isSchoolMember,
    groupMembers,
    isProfessional,
    isPaying,
    isLegacyTier,
    refreshDesigner() {
      return refetch()
    },
  }
}

export function useCurrentDesignerStripeCustomer() {
  const { result, loading, refetch } = useQuery<{
    user?: { designer?: { stripeCustomer?: StripeCustomer } }
  }>(gql`
    ${stripeCustomerFragment}
    query getCurrentDesigner {
      user {
        id
        designer {
          stripeCustomer {
            ...StripeCustomerProps
          }
        }
      }
    }
  `)

  const customer = computed(() => {
    return result.value?.user?.designer?.stripeCustomer
  })

  return {
    customer,
    loading,
    refetch,
  }
}
