import { gql } from '@apollo/client/core'
import {
  projectBudgetFragment,
  projectThingBudgetFragment,
  sectionBudgetFragment,
} from '/@/common/budget/fragments'
import { projectPermissionFragment } from './permissions'
import { categoryFragment } from '/@/common/categories/fragments'
import { mockupAccessFragment } from '/@/common/viz/accessFragments'
import { paletteAccessFragment } from '/@/common/palettes/accessFragments'

export const projectThingFeedbackFragment = gql`
  fragment Feedback on ProjectThing {
    feedback {
      note
      rating
      reason
      createdAt
    }
  }
`

export const projectThingFragment = gql`
  ${projectThingFeedbackFragment}
  fragment ProjectThingCommonProps on ProjectThing {
    id
    thingType
    ordering
    addedToSectionAt
    createdAt
    updatedAt
    layoutFormat
    ...Feedback
  }
`

export const projectAccessFragment = gql`
  fragment ProjectAccessProps on ProjectAccess {
    canView
    canEdit
    canEditThings
    canDelete
    canEditBranding
    canEditPortfolioProject
    canEditSettings
    canGiveFeedback
    canApproveBudget
    canViewBudget
    canEditShopList
    canViewPermissions
    canUpdateStatus
    canViewProjectHub
    role
    editUpgradeTier
  }
`

export const sectionFragment = gql`
  ${sectionBudgetFragment}
  fragment SectionProps on Section {
    id
    name
    note
    isVisible
    parentSectionId
    parentSection {
      id
      name
      note
      isVisible
      budget {
        ...SectionBudgetProps
      }
      roomType
      roomTypeExtra
    }
    budget {
      ...SectionBudgetProps
    }
    roomType
    roomTypeExtra
  }
`

export const projectThingBookmarkDataFragment = gql`
  fragment ProjectThingThingProps on Thing {
    id
    bookmarkInfo {
      id
    }
    thingType
    addedByDesignerId
    addedBy {
      id
      firstName
      lastName
      imageUrl
      nickname
    }
    myKudos {
      thingType
      givenBy {
        id
      }
    }
    ... on Image {
      url
      name
      paletteApiUrl
    }
    ... on Palette {
      name
      colors {
        hex
      }
    }
  }
`

export const projectImageQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBookmarkDataFragment}
  fragment ImageProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectImage {
      imageUrl
      name
      note
      thingType
      thing {
        ...ProjectThingThingProps
      }
      image {
        id
        url
        sourceSiteUrl
        credit
      }
    }
  }
`
export const projectGenericThingQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBudgetFragment}
  ${categoryFragment}
  fragment GenericThingProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectGenericThing {
      name
      vendor {
        id
        name
        isVerified
      }
      note
      thingType
      images {
        id
        url
      }
      imageUrl
      thingBudget {
        ...ProjectThingBudgetProps
      }
      category {
        ...CategoryProps
      }
      projectGenericThingSource
    }
  }
`

export const projectProductQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBookmarkDataFragment}
  ${projectThingBudgetFragment}
  ${categoryFragment}
  fragment ProductProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectProduct {
      name
      note
      imageUrl
      thing {
        ...ProjectThingThingProps
      }
      product {
        name
        minPrice
        maxPrice
        minPriceCent
        maxPriceCent
        description
        categoryKey
        category {
          ...CategoryProps
        }
        vendor {
          id
          name
          isVerified
        }
        vendorUrl
        cleanVendorUrl
        id
        thingType
        editable
        isVerified
        addedByDesignerId
      }
      url
      cleanUrl
      affiliateUrl
      vendor {
        id
        name
        isVerified
      }
      price
      thingBudget {
        ...ProjectThingBudgetProps
      }
      category {
        ...CategoryProps
      }
    }
  }
`
export const projectProductWithImagesQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBookmarkDataFragment}
  ${projectThingBudgetFragment}
  ${categoryFragment}
  fragment ProductWithImagesProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectProduct {
      name
      note
      imageUrl
      thing {
        ...ProjectThingThingProps
      }
      product {
        name
        minPrice
        maxPrice
        minPriceCent
        maxPriceCent
        description
        categoryKey
        category {
          ...CategoryProps
        }
        images {
          id
          url
        }
        vendor {
          id
          name
          isVerified
        }
        vendorUrl
        cleanVendorUrl
        id
        editable
        isVerified
        addedByDesignerId
      }
      url
      vendor {
        id
        name
        isVerified
      }
      price
      images {
        id
        url
      }
      thingBudget {
        ...ProjectThingBudgetProps
      }
      category {
        ...CategoryProps
      }
    }
  }
`
export const projectMockupQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBookmarkDataFragment}
  ${mockupAccessFragment}
  fragment ProjectMockupProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectMockup {
      name
      note
      thing {
        ...ProjectThingThingProps
      }
      mockup {
        id
        thingType
        imageUrl
        name
        note
        projectId
        mockupType
        designer {
          id
          firstName
          lastName
        }
        products {
          id
          productId
        }
        access {
          ...MockupAccessProps
        }
        updatedAt
      }
    }
  }
`
export const projectPaletteQueryFragment = gql`
  ${projectThingFragment}
  ${projectThingBookmarkDataFragment}
  ${paletteAccessFragment}
  fragment ProjectPaletteProps on ProjectThing {
    ...ProjectThingCommonProps
    ... on ProjectPalette {
      note
      thing {
        ...ProjectThingThingProps
      }
      palette {
        id
        thingType
        name
        colors {
          hex
        }
        access {
          ...PaletteAccessProps
        }
        designer {
          id
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`

export const projectThingQueryFragment = gql`
  ${projectImageQueryFragment}
  ${projectProductQueryFragment}
  ${projectMockupQueryFragment}
  ${projectPaletteQueryFragment}
  ${projectGenericThingQueryFragment}
  ${sectionFragment}
  fragment ProjectThingProps on ProjectThing {
    id
    section {
      ...SectionProps
    }
    ...ImageProps
    ...ProductProps
    ...ProjectMockupProps
    ...ProjectPaletteProps
    ...GenericThingProps
  }
`

export const projectFragment = gql`
  ${projectBudgetFragment}
  ${projectPermissionFragment}
  ${projectAccessFragment}
  ${sectionFragment}
  fragment ProjectProps on Project {
    id
    name
    updatedAt
    clientType
    visibility
    productDisplayType
    projectType
    status
    taxPercentage
    markupPercentage
    thingsLayout
    designer {
      firstName
      lastName
      tier
      nickname
      imageUrl
      id
    }
    dailyPlay {
      id
      prompt
      designerInfo {
        id
        completedAt
        dismissedAt
      }
    }
    sections {
      ...SectionProps
    }
    defaultImageUrl
    cover {
      imageUrl
      note
    }
    budget {
      ...ProjectBudgetProps
    }
    permissions {
      ...ProjectPermissionProps
    }
    access {
      ...ProjectAccessProps
    }
  }
`

export const projectWithThingsFragment = gql`
  ${projectFragment}
  ${projectThingQueryFragment}
  fragment ProjectWithThingsProps on Project {
    ...ProjectProps
    things {
      ...ProjectThingProps
    }
  }
`

export const projectQuery = gql`
  ${projectWithThingsFragment}
  query getProjectThings($projectId: String!) {
    project(id: $projectId) {
      ...ProjectWithThingsProps
    }
  }
`
