import type { Project } from '/@/common/projects/types'
import type { Task } from '/@/common/school/types'
import type {
  MockupAccess,
  MockupFill,
  MockupProduct,
} from '/@/common/things/types'
import type { DailyPlay } from '/@/common/daily-play/types'
import type { Room, RoomType } from '/@/common/rooms/types'
import type {
  DesignChallenge,
  DesignChallengeSubmission,
} from '/@/common/designChallenges/types'
import type { MockupTemplate } from '/@/common/templates/types'
import type { ScaledImages } from '/@/common/images/types'

export type MockupType = 'MOCKUP' | 'FLOOR_PLAN' | 'EDITORIAL' | 'ELEVATION'
export type ViziLimitType = 'MAGIC_ERASE' | 'ASSET'
export function isMockupType(value?: string): value is MockupType {
  if (!value) return false
  return ['MOCKUP', 'FLOOR_PLAN', 'EDITORIAL', 'ELEVATION'].includes(value)
}

export type SetupState =
  | 'IDLE'
  | 'REQUIRED'
  | 'COMPLETE'
  | 'TEMPLATE_SELECTION'
  | 'TEMPLATE_DETAILS'
  | 'CUSTOM_UPLOAD'
  | 'CUSTOM_UPLOAD_DETAILS'
  | 'CUSTOM_TEMPLATE_DETAILS'
  | 'THINGS_REPLACEMENT'

export type MockupLimit = {
  id: string
  mockupId: string
  mockupLimitType: ViziLimitType
  current: number
  limit: number
}

export interface ViziVersion {
  id: string
  /**
   * @deprecated data has been replaced by dataRaw
   */
  data?: string

  dataRaw?: string
  dataUrl?: string
  imageUrl: string
  createdAt: string
  mockupId: string
  designerId: string
}

export interface MockupOption {
  name: string
  hidden: boolean
  note: string
  imageUrl: string
  scaledImages: Pick<ScaledImages, 'PX_1200' | 'PX_2400'>
  palettes: {
    id: string
  }[]
  products: {
    id: string
  }[]
  images: {
    id: string
  }[]
}

export interface Vizi {
  id: string
  name: string
  note?: string
  mockupType: MockupType
  isTemplate: boolean
  setupState?: SetupState

  /**
   * @deprecated data has been replaced by dataRaw
   */
  data?: string

  dataRaw?: string
  dataUrl?: string
  imageUrl: string
  costVisibility: 'ON' | 'OFF'
  project?: Pick<
    Project,
    | 'id'
    | 'name'
    | 'visibility'
    | 'access'
    | 'projectType'
    | 'productDisplayType'
    | 'status'
    | 'clientType'
  >
  section?: {
    id: string
    name: string
  }
  access: MockupAccess
  designerId?: string
  designer?: {
    id: string
    nickname: string
    firstName: string
    lastName: string
  }
  dailyPlay?: DailyPlay
  task?: Task
  defaultBackgroundImageUrl?: string
  template?: {
    id: string
  }
  mockupTemplate?: MockupTemplate
  designChallenge?: DesignChallenge
  designChallengeSubmission?: DesignChallengeSubmission
  versions?: ViziVersion[]
  latestVersion?: ViziVersion
  vizVersion?: string
  isEdited: boolean
  limits: MockupLimit[]
  roomType?: RoomType
  roomTypeExtra?: string
  room?: Room
  scaledImages?: ScaledImages
  products?: MockupProduct[]
  fills?: MockupFill[]
  options?: MockupOption[]
  optionGroups: Array<{
    id: string
    name?: string
    options: Array<{
      id: string
      name: string
      isSelected: boolean
    }>
  }>
}
