import { gql } from '@apollo/client/core'
import { designerAddOnFragment } from '/@/common/addons/fragments'

export const subscriptionFragment = gql`
  fragment SubscriptionProps on Subscription {
    id
    subscriptionType
    stripeSubscriptionInterval
    stripeSubscriptionId
    createdAt
    trialDays
    trialCompletedAt
    cancelAt
    canceledAt
    cancelType
  }
`

export const subscriptionScheduleFragment = gql`
  fragment SubscriptionScheduleProps on SubscriptionSchedule {
    id
    subscriptionType
    stripeSubscriptionScheduleId
    stripeSubscriptionId
    tier
    status
    subscriptionType
    createdAt
  }
`

export const stripeDiscountFragment = gql`
  fragment StripeDiscountProps on StripeDiscount {
    id
    name
    description
    terms
    amountOff
    amountOffCents
    percentOff
    duration {
      durationType
      durationInMonths
    }
    appliesToPrices
    details
  }
`

export const stripeInvoiceFragment = gql`
  fragment StripeInvoiceProps on StripeInvoice {
    id
    tax
    subtotal
    discount
    amountDue
  }
`

export const stripeCustomerFragment = gql`
  fragment StripeCustomerProps on StripeCustomer {
    id
    tax {
      automaticTaxType
    }
    defaultPaymentMethod {
      id
      paymentType
      card {
        id
        brand
        last4
      }
    }
  }
`

export const stripeSubscriptionFragment = gql`
  ${stripeDiscountFragment}
  ${stripeInvoiceFragment}
  ${stripeCustomerFragment}
  fragment StripeSubscriptionProps on StripeSubscription {
    id
    cancelAtPeriodEnd
    currentPeriodEndAt
    customer {
      ...StripeCustomerProps
    }
    items {
      id
      quantity
      price {
        id
        nickname
        unitAmount
        recurring {
          id
          interval
          intervalCount
        }
      }
    }
    discounts {
      ...StripeDiscountProps
    }
    upcomingInvoice {
      ...StripeInvoiceProps
    }
  }
`

export const accountMembershipFragment = gql`
  ${designerAddOnFragment}
  ${subscriptionFragment}
  ${subscriptionScheduleFragment}
  fragment AccountMembershipProps on AccountMembership {
    id
    role
    access {
      id
      role
      canEditTeam
      canEditSubscription
    }
    account {
      id
      name
      tier
      accountType
      freeTierV1Experiment
      members {
        id
        role
        emailAddress
        designer {
          id
          firstName
          lastName
          nickname
          imageUrl
          sign
        }
      }
      invites {
        id
        firstName
        lastName
        emailAddress
        status
      }
      subscriptions {
        ...SubscriptionProps
      }
      subscriptionSchedules {
        ...SubscriptionScheduleProps
      }
      limits {
        id
        accountLimitType
        current
        base
        additional
        limit
      }
      addOns {
        ...DesignerAddOnProps
      }
    }
  }
`

export const accountMemberInviteWithExistingFragment = gql`
  fragment AccountMemberInviteWithExistingProps on AccountMemberInvite {
    id
    firstName
    lastName
    emailAddress
    status
    existingDesigner {
      id
      firstName
      lastName
    }
    account {
      id
      name
    }
  }
`
