import { computed, reactive, type Ref } from 'vue'
import { useRoute } from 'vue-router'
import { device } from '/@/utils/responsive'
import { useAppState } from '/@/App/state'
import type { StellaButtonType } from '/@/stella/types'
import { useDesignerBranding } from '/@/data/designer/branding'

interface ColorConfig {
  bgClass: string
  bgColor: string
  button: StellaButtonType
}

interface State {
  topbarVisible: boolean
  sidebarVisible: boolean
  leftNavVisible: boolean
  colorConfig: Ref<ColorConfig>
}

function create() {
  const app = useAppState()
  const designerBranding = useDesignerBranding()

  return reactive<State>({
    topbarVisible: false,
    sidebarVisible: false,
    leftNavVisible: true,
    colorConfig: computed<ColorConfig>(() => {
      if (app.brandingEnabled && designerBranding.cssValues.navigation) {
        return {
          bgClass: `bg-[${designerBranding.cssValues.navigation}]`,
          bgColor: designerBranding.cssValues.navigation,
          button: 'ghost',
        }
      } else if (app.stellaNavType === 'default') {
        return {
          bgClass: 'bg-pink-80',
          bgColor: '#782C34',
          button: 'ghost',
        }
      } else {
        if (app.navTheme === 'light') {
          return {
            bgClass: 'bg-cream-30',
            bgColor: '#FBF6EF',
            button: 'ghost-secondary',
          }
        } else {
          return {
            bgClass: 'bg-cream-90',
            bgColor: '#937258',
            button: 'ghost',
          }
        }
      }
    }),
  })
}

let cachedApi: ReturnType<typeof create>

export function useNavState() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}

export type StellaNavBarDisplayType =
  | 'default'
  | 'tall'
  | 'default with top'
  | 'tall with top'

export function useNavHeight() {
  const state = useNavState()
  const route = useRoute()

  const shouldHideMobileNavBar = computed(() => route.meta.mode !== 'edit')

  const displayType = computed<StellaNavBarDisplayType>(() => {
    if (device.isMd && !shouldHideMobileNavBar.value) {
      if (state.topbarVisible) {
        return 'tall with top'
      } else {
        return 'tall'
      }
    } else {
      if (state.topbarVisible) {
        return 'default with top'
      } else {
        return 'default'
      }
    }
  })

  const totalHeight = computed<number>(() => {
    switch (displayType.value) {
      case 'default':
        return 60
      case 'default with top':
        return 100
      case 'tall':
        return 120
      case 'tall with top':
        return 160
      default:
        return 60
    }
  })

  return {
    displayType,
    totalHeight,
    shouldHideMobileNavBar,
  }
}
