import {
  Experiment,
  ExperimentClient,
  type Exposure,
  type ExposureTrackingProvider,
  type Variant,
} from '@amplitude/experiment-js-client'
import { useConfig } from '/@/core/config'
import { type Ref, ref, watchEffect, watch } from 'vue'
import {
  type ExternalFeatureId,
  type LocalFeatureId,
  isExternalFeature,
} from '/@/core/feature/types'
import { getLocalFeatureState } from '../feature'
import { useRollbar } from '/@/core/rollbar'
import {
  localVariantState,
  type ExperimentId,
  type FeatureFlagVariantState,
} from '/@/core/experiment/types'

class SegmentExposureTrackingProvider implements ExposureTrackingProvider {
  private analytics: SegmentAnalytics.AnalyticsJS
  constructor(analytics: SegmentAnalytics.AnalyticsJS) {
    this.analytics = analytics
  }

  track(exposure: Exposure) {
    this.analytics.track('$exposure', exposure)
  }
}

let _experimentClient: ExperimentClient | undefined = undefined

function useExperimentClient() {
  const config = useConfig()
  const rollbar = useRollbar()

  const usingAmplitudeAnalytics = Boolean(config.apiKeys.amplitude)

  function getOrCreateExperiment() {
    if (_experimentClient) return _experimentClient
    const deploymentKey = config.apiKeys.amplitudeExperiment

    if (!deploymentKey) {
      rollbar.error(`[Experiment] No deployment key found`)
      return
    }
    if (!usingAmplitudeAnalytics && !window.analytics) {
      rollbar.error(`[Experiment] No analytics provider found`)
      return
    }

    _experimentClient = usingAmplitudeAnalytics
      ? Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
          automaticExposureTracking: false,
        })
      : Experiment.initialize(deploymentKey, {
          exposureTrackingProvider: new SegmentExposureTrackingProvider(
            window.analytics,
          ),
          automaticExposureTracking: false,
        })

    return _experimentClient
  }

  function trackExposureImpl(key: string) {
    const experimentClient = getOrCreateExperiment()
    if (!experimentClient) return
    experimentClient.exposure(key)
  }

  function trackExposure(key: string) {
    if (usingAmplitudeAnalytics) {
      trackExposureImpl(key)
      return
    }

    if (!window.analytics) return
    if (!window.analytics.user) {
      window.analytics?.ready(() => trackExposureImpl(key))
    } else {
      trackExposureImpl(key)
    }
  }

  return { usingAmplitudeAnalytics, getOrCreateExperiment, trackExposure }
}

const _variants = new Map<ExperimentId, Variant>()

export function useExperiment(
  experimentId: ExperimentId,
  hasAutomaticExposure: boolean = true,
  shouldFetch?: Ref<boolean>,
) {
  const { usingAmplitudeAnalytics, getOrCreateExperiment, trackExposure } =
    useExperimentClient()
  const rollbar = useRollbar()
  const config = useConfig()

  const variant = ref<Variant | null>(null)
  const loading = ref(false)

  async function fetchVariantImpl() {
    loading.value = true
    if (config.isLocal) {
      setTimeout(() => {
        variant.value = localVariantState[experimentId]
        loading.value = false
      }, 100)
    }

    try {
      const cachedVariant = _variants.get(experimentId)
      if (cachedVariant) {
        variant.value = cachedVariant
        loading.value = false
        if (hasAutomaticExposure) {
          _experimentClient?.exposure(experimentId)
        }
        return
      }

      const experimentClient = getOrCreateExperiment()
      if (!experimentClient) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to create experiment client`)
        return
      }

      let userInfo

      if (!usingAmplitudeAnalytics) {
        const user = window.analytics.user()
        const userId = user.id() ?? undefined
        const deviceId = user.anonymousId()
        userInfo = {
          user_id: userId,
          device_id: deviceId,
          version: config.version,
        }
      }

      const clientWithVariants = await experimentClient.fetch(userInfo)

      if (!clientWithVariants) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to fetch variants`)
        return
      }

      variant.value = clientWithVariants.variant(experimentId) ?? null
      if (hasAutomaticExposure && variant.value) {
        experimentClient.exposure(experimentId)
      }
      loading.value = false
    } catch (e) {
      rollbar.error(`[Experiment] Exception starting the experiment`, e)
      loading.value = false
      return
    }
  }

  async function fetchVariant() {
    if (usingAmplitudeAnalytics) {
      fetchVariantImpl()
      return
    }

    if (!window.analytics) return
    if (!window.analytics.user) window.analytics?.ready(fetchVariantImpl)
    else fetchVariantImpl()
  }

  watchEffect(() => {
    const cachedVariant = _variants.get(experimentId)
    if (!cachedVariant) return
    variant.value = cachedVariant
    loading.value = false
  })

  // fetch if no arg passed
  if (!shouldFetch) {
    fetchVariant()
  }

  watch(
    () => shouldFetch?.value,
    f => {
      if (!f) return
      fetchVariant()
    },
    {
      immediate: true,
    },
  )

  return { variant, loading, trackExposure: () => trackExposure(experimentId) }
}

const _featureFlags = new Map<ExternalFeatureId, FeatureFlagVariantState>()

export function useFeatureFlag(
  flag: ExternalFeatureId | LocalFeatureId,
  hasAutomaticExposure: boolean = true,
  shouldFetch?: Ref<boolean>,
) {
  const { usingAmplitudeAnalytics, getOrCreateExperiment, trackExposure } =
    useExperimentClient()
  const rollbar = useRollbar()
  const config = useConfig()

  const featureFlag = ref<string | null>(null)
  const loading = ref(false)

  async function fetchVariantImpl() {
    loading.value = true
    if (config.isLocal) {
      setTimeout(() => {
        const localFeatureState = getLocalFeatureState()
        featureFlag.value = localFeatureState[flag] ? 'treatment' : 'control'
        loading.value = false
      }, 100)
    }

    try {
      if (!isExternalFeature(flag)) {
        loading.value = false
        return
      }
      const cachedFlag = _featureFlags.get(flag)
      if (cachedFlag) {
        featureFlag.value = cachedFlag.variant.value
        loading.value = cachedFlag.loading.value
        if (hasAutomaticExposure) {
          _experimentClient?.exposure(flag)
        }
        return
      }
      _featureFlags.set(flag, { variant: featureFlag, loading })
      const experimentClient = getOrCreateExperiment()
      if (!experimentClient) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to create experiment client`)
        return
      }

      let userInfo

      if (!usingAmplitudeAnalytics) {
        const user = window.analytics.user()
        const userId = user.id() ?? undefined
        const deviceId = user.anonymousId()
        userInfo = {
          user_id: userId,
          device_id: deviceId,
          version: config.version,
        }
      }

      const clientWithVariants = await experimentClient.fetch(userInfo)

      if (!clientWithVariants) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to fetch variants`)
        return
      }

      featureFlag.value = clientWithVariants.variant(flag).value ?? null
      if (hasAutomaticExposure && featureFlag.value) {
        experimentClient.exposure(flag)
      }
      loading.value = false
    } catch (e) {
      rollbar.error(`[Experiment] Exception starting the experiment`, e)
      loading.value = false
      return
    }
  }

  async function fetchVariant() {
    if (usingAmplitudeAnalytics) {
      fetchVariantImpl()
      return
    }

    if (!window.analytics) return
    if (!window.analytics.user) window.analytics?.ready(fetchVariantImpl)
    else fetchVariantImpl()
  }

  watchEffect(() => {
    if (!isExternalFeature(flag)) {
      return
    }
    const globalVariantState = _featureFlags.get(flag)
    if (!globalVariantState) return
    featureFlag.value = globalVariantState.variant.value
    loading.value = globalVariantState.loading.value
  })

  // fetch if no arg passed
  if (!shouldFetch) {
    fetchVariant()
  }

  watch(
    () => shouldFetch?.value,
    f => {
      if (!f) return
      fetchVariant()
    },
    {
      immediate: true,
    },
  )

  return { featureFlag, loading, trackExposure: () => trackExposure(flag) }
}

export function clearExperiments() {
  _variants.clear()
  _featureFlags.clear()
}
