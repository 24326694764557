import { createModal } from '/@/core/elements/modal'

interface TermsModalData {
  contentMode: 'plain text' | 'html'
  loadContent: () => Promise<string>
  onAccept: () => void
  onCancel: () => void
}

const { api, component } = createModal<TermsModalData>(
  'TermsModal',
  () => import('./TermsModal.vue'),
  {
    contentMode: 'plain text',
    loadContent: () => Promise.resolve(''),
    onAccept: () => void 0,
    onCancel: () => void 0,
  },
)

export const useTermsModal = api
export default component
