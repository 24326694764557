import { gql } from '@apollo/client/core'

export const designerAddOnFragment = gql`
  fragment DesignerAddOnProps on DesignerAddOn {
    id
    designerId
    addOnType
    thingId
    thingType
    count
  }
`
