import { reactive, toRefs, computed } from 'vue'
import { type Branding } from '/@/data/designer/types'
import { useDesignerApi } from '../api'
import { useAppState } from '/@/App/state'
import tinycolor from 'tinycolor2'

interface State {
  id: string | null
  nickname: string | null
  website: string | null
  branding: Branding
}

function create() {
  const api = useDesignerApi()
  const app = useAppState()

  const state = reactive<State>({
    id: null,
    nickname: null,
    website: null,
    branding: {
      backgroundColor: null,
      primaryColor: null,
      secondaryColor: null,
      logoImageUrl: null,
    },
  })

  function clear() {
    state.id = null
    state.nickname = null
    state.branding = {
      backgroundColor: null,
      primaryColor: null,
      secondaryColor: null,
      logoImageUrl: null,
    }
    state.website = null
  }

  async function fetch({ nickname = '' }) {
    if (!nickname) return

    const designer = await api.fetch({ nickname })

    if (!designer) return

    state.id = designer.id
    state.nickname = designer.nickname
    state.website = designer.website || null
    state.branding = designer.branding
  }

  function setBranding(newBranding: Branding) {
    state.branding.primaryColor = newBranding.primaryColor
    state.branding.secondaryColor = newBranding.secondaryColor
    state.branding.backgroundColor = newBranding.backgroundColor
    state.branding.logoImageUrl = newBranding.logoImageUrl
  }

  const hasBranding = computed(() =>
    Boolean(
      state.branding.primaryColor ||
        state.branding.secondaryColor ||
        state.branding.backgroundColor ||
        state.branding.logoImageUrl,
    ),
  )

  const classes = computed(() => {
    if (!app.brandingEnabled) return
    const classes = []
    if (state.branding.primaryColor) {
      classes.push('brand-navigation-enabled')
    }
    if (state.branding.secondaryColor) {
      classes.push('brand-accent-enabled')
      const tc = tinycolor(state.branding.secondaryColor)
      const isDark = tc.getBrightness() < 110 && tc.getAlpha() > 0.5
      if (isDark) classes.push('brand-accent-is-dark')
      else classes.push('brand-accent-is-light')
    }
    return classes.join(' ')
  })
  const cssValues = computed(() => {
    let navigationText = 'white'
    let navigationLogo = 'white'
    if (state.branding.primaryColor) {
      const tc = tinycolor(state.branding.primaryColor)
      const isTooDark = tc.getBrightness() < 110 && tc.getAlpha() > 0.5
      navigationText = isTooDark
        ? /* cream-40 */ 'white'
        : /* grey-80 */ '#272727'
      navigationLogo = isTooDark
        ? /* cream-40 */ '#F7EFE3'
        : /* cream-90 */ '#5B3921'
    }

    return {
      navigation: state.branding.primaryColor,
      accent: state.branding.secondaryColor,
      navigationText,
      navigationLogo,
    }
  })

  return reactive({
    ...toRefs(state),
    fetch,
    clear,
    hasBranding,
    setBranding,
    classes,
    cssValues,
    updateBranding: api.updateBranding,
  })
}

let cachedApi: ReturnType<typeof create>

export function useDesignerBranding() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}
