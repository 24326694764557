import { gql } from '@apollo/client/core'

export const classFragment = gql`
  fragment ClassProps on Class {
    id
    title
    subtitle
    description
    url
    classType
    status
    goal {
      id
    }
    designerInfo {
      id
      completedAt
      note
    }
  }
`

export const taskFragment = gql`
  fragment TaskProps on Task {
    id
    title
    subtitle
    description
    ctaLabel
    imageUrl
    tutorialImageUrl
    tutorialUrl
    tierRestrictions
    goal {
      id
    }
    addOn {
      price
      priceCents
      confirmationMessage
    }
    designerInfo {
      id
      lastPurchasedAt
      completedAt
      projectId
      goToProjectEditor
      url
      mockup {
        id
      }
      palette {
        id
      }
    }
  }
`

export const goalDesignerInfoFragment = gql`
  fragment GoalDesignerInfoProps on DesignerGoal {
    id
    status
    completedAt
  }
`

export const goalFragment = gql`
  ${classFragment}
  ${taskFragment}
  ${goalDesignerInfoFragment}
  fragment GoalProps on Goal {
    id
    track {
      id
      title
      description
      group
      imageUrl
    }
    description
    taskDescription
    taskSummary
    level
    levelNumber
    levelTitle
    status
    classes {
      ...ClassProps
    }
    tasks {
      ...TaskProps
    }
    designerInfo(designerId: $designerId) {
      ...GoalDesignerInfoProps
    }
  }
`

export const schoolClassFragment = gql`
  fragment SchoolClassProps on SchoolClass {
    id
    schoolClassGroupId
    title
    url
    imageUrl
    classType
    proOnly
  }
`

export const schoolClassGroupFragment = gql`
  ${schoolClassFragment}
  fragment SchoolClassGroupProps on SchoolClassGroup {
    id
    name
    classes {
      ...SchoolClassProps
    }
  }
`
