import { type Ref, computed } from 'vue'
import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '/@/core/graphql'
import type {
  AccountMembership,
  SubscriptionUpdateMembershipInput,
  AccountMemberInviteInput,
  AccountMemberInvite,
  AccountMemberInviteStatusType,
  AccountNameInput,
  StripeSubscription,
  StripeSubscriptionSchedule,
  SubscriptionCancelFlowInfo,
  Subscription,
  SubscriptionSchedule,
} from './types'
import {
  accountMembershipFragment,
  accountMemberInviteWithExistingFragment,
  stripeDiscountFragment,
  stripeSubscriptionFragment,
  subscriptionScheduleFragment,
} from './fragments'
import type { CurrentDesigner } from '/@/data/designer/types'
import { evictFromCache } from '/@/utils/apollo'

export const getAccountsDataQuery = gql`
  ${accountMembershipFragment}
  query getCurrentDesignerAccounts {
    user {
      id
      designer {
        id
        accounts {
          ...AccountMembershipProps
        }
      }
    }
  }
`

export const getAccountInviteByIdQuery = gql`
  ${accountMemberInviteWithExistingFragment}
  query getAccountMemberInviteById($id: String!) {
    account {
      inviteById(id: $id) {
        ...AccountMemberInviteWithExistingProps
      }
    }
  }
`

export const getAccountStripeSubscriptionByIdQuery = gql`
  ${stripeSubscriptionFragment}
  query getAccountStripeSubscriptionById($id: String!) {
    account {
      stripeSubscriptionById(id: $id) {
        ...StripeSubscriptionProps
      }
    }
  }
`

export const getAccountStripeSubscriptionScheduleByIdQuery = gql`
  query getAccountStripeSubscriptionScheduleById($id: String!) {
    account {
      stripeSubscriptionScheduleById(id: $id) {
        id
        phases {
          startAt
          endAt
          items {
            price {
              id
              nickname
              unitAmount
              recurring {
                id
                interval
                intervalCount
              }
            }
          }
        }
      }
    }
  }
`

export const getSubscriptionCancelFlowInfoByIdQuery = gql`
  ${stripeDiscountFragment}
  query getSubscriptionCancelFlowInfoById($id: String!) {
    account {
      subscriptionCancelFlowInfoById(id: $id) {
        salvageDiscount {
          ...StripeDiscountProps
        }
      }
    }
  }
`

export function useAccountsData() {
  const { result, loading, refetch } = useQuery<{
    user?: {
      designer?: {
        accounts: AccountMembership[]
      }
    }
  }>(getAccountsDataQuery)

  const accounts = computed(() => result.value?.user?.designer?.accounts ?? [])

  const accountMemberships = computed<AccountMembership[]>(() =>
    accounts.value.filter(a => a.account.accountType === 'DESIGNER'),
  )

  const hasBrandAccounts = computed(() =>
    Boolean(
      accounts.value.filter(a => a.account.accountType === 'BRAND').length,
    ),
  )

  const ownedAccount = computed(
    () => accountMemberships.value?.find(a => a.role === 'OWNER')?.account,
  )

  const membershipSubscription = computed(
    () =>
      ownedAccount.value?.subscriptions.find(
        s => s.subscriptionType === 'MEMBERSHIP',
      ) ?? null,
  )

  const activeAccountMembership = computed(
    () =>
      accountMemberships.value?.find(a => a.account.tier !== 'FREE') ??
      accountMemberships.value?.find(a => a.role === 'OWNER'),
  )

  const activeAccount = computed(() => activeAccountMembership.value?.account)

  const isMembershipSubscriptionCanceling = computed(
    () =>
      membershipSubscription.value?.cancelAt &&
      activeAccountMembership.value?.account.tier !== 'FREE',
  )

  const teamName = computed(() => activeAccount.value?.name)

  const teamMembers = computed(() => activeAccount.value?.members ?? [])

  const teamInvites = computed(() => activeAccount.value?.invites ?? [])

  const canEditTeam = computed(
    () =>
      activeAccountMembership.value?.access.canEditTeam &&
      membershipSubscription.value,
  )

  const canEditSubscription = computed(
    () =>
      activeAccountMembership.value?.access.canEditSubscription &&
      membershipSubscription.value,
  )

  const viziLimit = computed(() =>
    activeAccount.value?.limits.find(l => l.accountLimitType === 'VIZIS'),
  )

  const advancedVizisLimit = computed(() =>
    activeAccount.value?.limits.find(
      l => l.accountLimitType === 'ADVANCED_VIZIS',
    ),
  )

  const paletteLimit = computed(() =>
    activeAccount.value?.limits.find(l => l.accountLimitType === 'PALETTES'),
  )

  const projectLimit = computed(() =>
    activeAccount.value?.limits.find(l => l.accountLimitType === 'PROJECT'),
  )

  const addOns = computed(() => activeAccount.value?.addOns ?? [])

  const isPaying = computed(
    () => ownedAccount.value?.tier && ownedAccount.value?.tier !== 'FREE',
  )

  const isTrialing = computed(
    () =>
      membershipSubscription.value &&
      membershipSubscription.value.trialDays &&
      membershipSubscription.value.trialDays > 0 &&
      !membershipSubscription.value.trialCompletedAt,
  )

  const reactivatingSubscriptionSchedule = computed(() =>
    ownedAccount.value?.subscriptionSchedules?.find(
      ss =>
        !ss.stripeSubscriptionId &&
        ss.tier &&
        (ss.status === 'NOT_STARTED' || ss.status === 'ACTIVE'),
    ),
  )

  return {
    accountMemberships,
    hasBrandAccounts,
    ownedAccount,
    activeAccountMembership,
    activeAccount,
    teamName,
    teamMembers,
    teamInvites,
    membershipSubscription,
    isMembershipSubscriptionCanceling,
    viziLimit,
    advancedVizisLimit,
    paletteLimit,
    projectLimit,
    canEditTeam,
    canEditSubscription,
    addOns,
    isPaying,
    isTrialing,
    reactivatingSubscriptionSchedule,
    loading,
    refetch,
  }
}

export function useAccountStripeSubscriptionData(
  stripeSubscriptionId: Ref<string | undefined | null>,
) {
  const { result, loading, refetch } = useQuery<{
    account: {
      stripeSubscriptionById: StripeSubscription
    }
  }>(
    getAccountStripeSubscriptionByIdQuery,
    { id: stripeSubscriptionId },
    () => ({ enabled: Boolean(stripeSubscriptionId?.value) }),
  )

  const stripeSubscription = computed(
    () => result.value?.account.stripeSubscriptionById,
  )

  return { stripeSubscription, loading, refetch }
}

export function useAccountStripeSubscriptionScheduleData(
  stripeSubscriptionScheduleId: Ref<string | undefined | null>,
) {
  const { result, loading, refetch } = useQuery<{
    account: {
      stripeSubscriptionScheduleById: StripeSubscriptionSchedule
    }
  }>(
    getAccountStripeSubscriptionScheduleByIdQuery,
    { id: stripeSubscriptionScheduleId },
    () => ({ enabled: Boolean(stripeSubscriptionScheduleId?.value) }),
  )

  const stripeSubscriptionSchedule = computed(
    () => result.value?.account.stripeSubscriptionScheduleById,
  )

  return { stripeSubscriptionSchedule, loading, refetch }
}

export function useAccountMemberInviteData(
  inviteId: Ref<string | undefined | null>,
) {
  const { result, loading, refetch } = useQuery<{
    account: {
      inviteById: AccountMemberInvite
    }
  }>(
    getAccountInviteByIdQuery,
    {
      id: inviteId,
    },
    () => ({
      enabled: Boolean(inviteId?.value),
    }),
  )

  return { result, loading, refetch }
}

export function useAccountSubscriptionCancelFlowInfoData(
  subscriptionId: Ref<string | undefined | null>,
) {
  const { result, loading } = useQuery<{
    account: {
      subscriptionCancelFlowInfoById?: SubscriptionCancelFlowInfo
    }
  }>(getSubscriptionCancelFlowInfoByIdQuery, { id: subscriptionId }, () => ({
    enabled: Boolean(subscriptionId?.value),
  }))

  const subscriptionCancelFlowInfo = computed(
    () => result.value?.account.subscriptionCancelFlowInfoById,
  )

  return { subscriptionCancelFlowInfo, loading }
}

export function useAccountsApi() {
  const { mutate: updateMembershipSubscription } = useMutation<{
    account: {
      subscription: {
        updateMembership: {
          status: number
          record: Pick<CurrentDesigner, 'id' | 'tier' | 'subscriptionInterval'>
        }
      }
    }
  }>(
    gql`
      mutation updateAccountSubscriptionMembership(
        $id: String!
        $input: SubscriptionUpdateMembershipInput!
      ) {
        account {
          subscription {
            updateMembership(id: $id, input: $input) {
              record {
                id
                tier
                subscriptionInterval
                accounts {
                  id
                  account {
                    id
                    subscriptions {
                      id
                      cancelType
                      cancelAt
                      canceledAt
                    }
                  }
                }
              }
              status
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: updateName } = useMutation<{
    account: {
      updateName: {
        recordId: Text
        status: number
        record: {
          id: Text
          name: Text
        }
      }
    }
  }>(
    gql`
      mutation updateName($input: AccountNameInput!) {
        account {
          updateName(input: $input) {
            recordId
            status
            record {
              id
              name
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: initiateSubscriptionSchedulePhase } = useMutation<{
    account: {
      subscriptionSchedule: {
        initiatePhase: {
          status: number
          record?: SubscriptionSchedule
        }
      }
    }
  }>(
    gql`
      ${subscriptionScheduleFragment}
      mutation initiateSubscriptionSchedulePhase($id: String!) {
        account {
          subscriptionSchedule {
            initiatePhase(id: $id) {
              status
              record {
                ...SubscriptionScheduleProps
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: releaseSubscriptionSchedule } = useMutation<{
    account: {
      subscriptionSchedule: {
        release: {
          status: number
          record?: SubscriptionSchedule
        }
      }
    }
  }>(
    gql`
      ${subscriptionScheduleFragment}
      mutation releaseSubscriptionSchedule($id: String!) {
        account {
          subscriptionSchedule {
            release(id: $id) {
              status
              record {
                ...SubscriptionScheduleProps
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: applySalvageDiscount } = useMutation<{
    account: {
      subscription: {
        applySalvageDiscount: {
          status: number
          record?: Subscription
        }
      }
    }
  }>(gql`
    ${stripeSubscriptionFragment}
    mutation applySalvageDiscount($id: String!) {
      account {
        subscription {
          applySalvageDiscount(id: $id) {
            status
            record {
              id
              stripeSubscription {
                ...StripeSubscriptionProps
              }
            }
          }
        }
      }
    }
  `)

  return {
    async updateMembershipSubscription(
      id: string,
      input: SubscriptionUpdateMembershipInput,
    ) {
      return await updateMembershipSubscription({ id, input })
    },
    async updateName(input: AccountNameInput) {
      return await updateName({ input })
    },
    async initiateSubscriptionSchedulePhase(id: string) {
      return await initiateSubscriptionSchedulePhase(
        { id },
        {
          update(cache, { data }) {
            const subscriptionScheduleId =
              data?.account?.subscriptionSchedule?.initiatePhase?.record?.id
            if (!subscriptionScheduleId) {
              evictFromCache(cache, id, 'SubscriptionSchedule')
            }
          },
        },
      )
    },
    async releaseSubscriptionSchedule(id: string) {
      return await releaseSubscriptionSchedule(
        { id },
        {
          update(cache, { data }) {
            const subscriptionScheduleId =
              data?.account?.subscriptionSchedule?.initiatePhase?.record?.id
            if (!subscriptionScheduleId) {
              evictFromCache(cache, id, 'SubscriptionSchedule')
            }
          },
        },
      )
    },
    async applySalvageDiscount(id: string) {
      return await applySalvageDiscount({ id })
    },
  }
}

export function useAccountMembersApi() {
  const { mutate: deleteMember } = useMutation<{
    account: {
      member: {
        delete: {
          status: number
        }
      }
    }
  }>(gql`
    mutation deleteMember($id: String!) {
      account {
        member {
          delete(id: $id) {
            status
          }
        }
      }
    }
  `)

  return {
    async deleteMember(id: string) {
      return await deleteMember({ id })
    },
  }
}

export function useAccountMemberInvitesApi() {
  const { mutate: createInvite } = useMutation<{
    account: {
      invite: {
        create: {
          status: number
        }
      }
    }
  }>(gql`
    mutation createInvite($input: AccountMemberInviteInput!) {
      account {
        invite {
          create(input: $input) {
            status
          }
        }
      }
    }
  `)

  const { mutate: acceptInvite } = useMutation<{
    account: {
      invite: {
        accept: {
          status: number
          record: {
            id: string
            status: AccountMemberInviteStatusType
          }
        }
      }
    }
  }>(gql`
    mutation acceptInvite($id: String!) {
      account {
        invite {
          accept(id: $id) {
            status
            record {
              id
              status
            }
          }
        }
      }
    }
  `)

  const { mutate: deleteInvite } = useMutation<{
    account: {
      invite: {
        delete: {
          status: number
        }
      }
    }
  }>(gql`
    mutation deleteInvite($id: String!) {
      account {
        invite {
          delete(id: $id) {
            status
          }
        }
      }
    }
  `)

  return {
    async createInvite(input: AccountMemberInviteInput) {
      return await createInvite({ input })
    },
    async acceptInvite(id: string) {
      return await acceptInvite({ id })
    },
    async deleteInvite(id: string) {
      return await deleteInvite({ id })
    },
  }
}
