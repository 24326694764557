import { gql } from '@apollo/client/core'
import { projectAccessFragment } from '/@/common/projects/api/fragments'

export const designerCurrentProjectFragment = gql`
  ${projectAccessFragment}
  fragment DesignerCurrentProjectProps on Project {
    id
    name
    updatedAt
    designer {
      id
      nickname
      firstName
      lastName
      tier
    }
    clientType
    visibility
    productDisplayType
    access {
      ...ProjectAccessProps
    }
    projectType
    status
  }
`
export const designerProjectsFragment = gql`
  ${projectAccessFragment}
  fragment DesignerProjectsProps on Project {
    id
    name
    clientType
    visibility
    cover {
      imageUrl
    }
    defaultImageUrl
    sections {
      id
      name
      parentSectionId
    }
    productDisplayType
    access {
      ...ProjectAccessProps
    }
    designer {
      id
      firstName
      lastName
      nickname
    }
    updatedAt
    projectType
    status
    taxPercentage
    markupPercentage
  }
`

export const designerCardDataFragment = gql`
  fragment DesignerCardDataProps on Designer {
    id
    firstName
    lastName
    imageUrl
    bio
    nickname
    hometown
    sign
    portfolioProjects {
      id
      imageUrl
    }
    visionBoard {
      id
      imageUrl
    }
    visionBoardImageUrl
  }
`

export const designerSignupDataFragment = gql`
  fragment DesignerSignupDataProps on Designer {
    id
    firstName
    lastName
    imageUrl
    nickname
    tier
  }
`
