import { reactive, toRefs, computed } from 'vue'

export interface Config {
  mode: 'production' | 'development' | 'e2e'
  env: 'local' | 'dev' | 'prod' | 'cypress'
  embed: boolean
  version: string
  apiKeys: {
    stripe: string
    spoak: string
    segment: string
    amplitude: string
    amplitudeExperiment: string
    pinterestClientId: string
    googleClientId: string
  }
  auth0: {
    clientId: string
    domain: string
    audience: string
  }
  urls: {
    api: string
    admin: string
    cdn: string
    gql: string
  }
  siteOutageBanner: string
  vizOutageBanner: string
}

function create(config: Config) {
  const state = reactive<Config>(config)

  const site = computed(() => {
    if (state.env === 'local') return 'http://local.spoak.com'
    return window.location.origin
  })

  function getShopOnboardingLink(routePath: string) {
    return `${config.urls.admin}/connect/start?returnUrl=${site.value}${routePath}`
  }

  const isEmbed = computed(() => state.embed)

  return reactive({
    ...toRefs(state),
    site,
    isEmbed,
    isLocal: computed(() => state.env === 'local'),
    getShopOnboardingLink,
  })
}

let config: ReturnType<typeof create>
export function createConfig(conf: Config) {
  config = create(conf)
  return config
}

export function useConfig() {
  if (!config) throw Error('App config was not created.')
  return config
}
