import type { Ref } from 'vue'
import type { Variant } from '@amplitude/experiment-js-client'

export type ExperimentId = 'buy-now-cta-text-v1'

export const localVariantState: Record<ExperimentId, Variant> = {
  'buy-now-cta-text-v1': {
    value: 'control',
    payload: { text: 'Buy now' },
  },
}

export interface FeatureFlag {
  flagValue: Ref<boolean>
  loading: Ref<boolean>
}

export interface FeatureFlagVariantState {
  variant: Ref<string | null>
  loading: Ref<boolean>
}
