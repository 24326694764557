import { gql } from '@apollo/client/core'

export const projectBudgetFragment = gql`
  fragment ProjectBudgetProps on ProjectBudget {
    id
    amount
    amountCents
    suggestedAmount
    suggestedAmountCents
    status
  }
`

export const sectionBudgetFragment = gql`
  fragment SectionBudgetProps on SectionBudget {
    id
    amount
    amountCents
  }
`

export const projectThingBudgetStatusChangeFragment = gql`
  fragment ProjectThingBudgetStatusChangeProps on ProjectThingBudgetStatusChange {
    id
    status
    designer {
      id
      imageUrl
      firstName
      lastName
    }
    client {
      id
      firstName
      lastName
    }
    createdAt
  }
`

export const projectThingBudgetFragment = gql`
  ${projectThingBudgetStatusChangeFragment}
  fragment ProjectThingBudgetProps on ProjectThingBudget {
    id
    price
    priceCents
    quantity
    shipping
    shippingCents
    total
    totalCents
    tax
    taxCents
    taxPercentage
    markup
    markupCents
    markupPercentage
    discount
    discountCents
    discountPercentage
    orderNumber
    trackingInfo
    leadTime
    estimatedDeliveryAt
    statusChanges {
      ...ProjectThingBudgetStatusChangeProps
    }
    status {
      ...ProjectThingBudgetStatusChangeProps
    }
  }
`
