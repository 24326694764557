import { type InjectionKey, inject } from 'vue'
import { InMemoryCache } from '@apollo/client/cache'
import { relayStylePagination } from '@apollo/client/utilities'
import possibleTypes from './possibleTypes.json'

export function createCache() {
  return new InMemoryCache({
    possibleTypes,
    typePolicies: {
      CommunityQueries: {
        merge: true,
      },
      BookmarkQueries: {
        merge: true,
        fields: {
          byIdentifier: relayStylePagination(),
        },
      },
      Designer: {
        fields: {
          branding: {
            merge: true,
          },
          follows: {
            merge: true,
          },
          shopBalance: {
            merge: true,
          },
          mockupList: relayStylePagination([
            'projectIds',
            'permissionTypes',
            'designerIds',
            'mockupTypes',
            'text',
          ]),
          paletteList: relayStylePagination([
            'permissionTypes',
            'designerIds',
            'projectIds',
            'text',
          ]),
          projectList: relayStylePagination([
            'permissionTypes',
            'designerIds',
            'projectStatuses',
            'text',
          ]),
          bookmarkList: relayStylePagination(['types', 'text']),
        },
      },
      MockupTemplateGroupQueries: {
        fields: {
          list: relayStylePagination([
            'identifier',
            'text',
            'taxonomies',
            'mockupTypes',
            'roomTypes',
          ]),
        },
      },
      MockupTemplateQueries: {
        fields: {
          list: relayStylePagination([
            'identifier',
            'text',
            'taxonomies',
            'mockupTypes',
            'roomTypes',
          ]),
        },
      },
      Follows: {
        fields: {
          following: {
            merge: true,
          },
          followers: {
            merge: true,
          },
        },
      },
      Project: {
        fields: {
          dailyPlay: {
            merge: true,
          },
          cover: {
            merge: true,
          },
          access: {
            merge: true,
          },
        },
      },
      Mockup: {
        fields: {
          access: {
            merge: true,
          },
          scaledImages: {
            merge: true,
          },
        },
      },
      Palette: {
        fields: {
          access: {
            merge: true,
          },
        },
      },
      StripeCustomer: {
        fields: {
          tax: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          searchThings: relayStylePagination([
            'filters',
            'excludes',
            'sort',
            'minPrice',
            'maxPrice',
            'boostType',
            'types',
            'designerId',
            'vendorId',
            'text',
            'popularOnly',
            'productIsVerified',
            'badges',
            'imageUrl',
            'thingTypes',
            'mockupTypes',
            'roomTypes',
            'query',
          ]),
          searchPaint: relayStylePagination(['query', 'filters', 'sort']),
          searchTiles: relayStylePagination(['query', 'filters', 'sort']),
          searchWallpaper: relayStylePagination(['query', 'filters', 'sort']),
          designerImages: relayStylePagination([
            'designerId',
            'text',
            'projectIds',
            'sectionIds',
            'imageType',
            'ownedByDesigner',
          ]),
          discountList: relayStylePagination([
            'discountTypes',
            'discountUsageTypes',
            'vendorIds',
            'text',
          ]),
        },
      },
      SignupQueries: {
        merge: true,
      },
      MembershipPlanQueries: {
        merge: true,
      },
      StockImageQueries: {
        merge: true,
      },
      SignupMutations: {
        merge: true,
      },
      UserMutations: {
        merge: true,
      },
      ThingQueries: {
        merge: true,
      },
    },
  })
}

export type GraphqlCache = ReturnType<typeof createCache>
export const GraphqlCacheSymbol: InjectionKey<GraphqlCache> =
  Symbol('graphql-cache')

export function useGraphqlCache() {
  const cache = inject(GraphqlCacheSymbol)
  if (!cache) throw Error('Apollo Graphql cache was not correctly created.')
  return cache
}
