import { version } from '../package.json'

export const isProductionBuild = import.meta.env.MODE === 'production'
export const isProductionEnvironment =
  //@ts-ignore
  typeof window !== 'undefined' &&
  // @ts-ignore
  window.__app_config__ &&
  // @ts-ignore
  window.__app_config__.env === 'prod'

const devModeConfig = {
  version,
  mode: import.meta.env.MODE || 'development',
  env: import.meta.env.VITE_APP_ENV || 'local',
  embed: import.meta.env.VITE_APP_EMBED || false,
  apiKeys: {
    stripe: import.meta.env.VITE_APP_STRIPE_API_KEY || '',
    spoak: import.meta.env.VITE_APP_DEV_API_KEY || '',
    segment: import.meta.env.VITE_APP_SEGMENT_API_KEY || '',
    amplitude: import.meta.env.VITE_APP_AMPLITUDE_API_KEY || '',
    amplitudeExperiment:
      import.meta.env.VITE_APP_AMPLITUDE_DEPLOYMENT_KEY || '',
    pinterestClientId: import.meta.env.VITE_APP_PINTEREST_CLIENT_ID || '',
    googleClientId: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID || '',
  },
  auth0: {
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID || '',
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN || 'dev-spoak.auth0.com',
    audience:
      import.meta.env.VITE_APP_AUTH0_AUDIENCE || 'https://dev-api.spoak.com',
  },
  urls: {
    api: import.meta.env.VITE_APP_API_URL || '/api',
    admin: import.meta.env.VITE_APP_ADMIN_URL || '',
    cdn: import.meta.env.VITE_APP_CDN_URL || 'https://cdn.spoak.com',
    gql: import.meta.env.VITE_APP_GQL_API_URL || '',
  },
  siteOutageBanner: import.meta.env.SITE_OUTAGE_BANNER || '',
  vizOutageBanner: import.meta.env.VIZ_OUTAGE_BANNER || '',
}

export function getConfig() {
  // @ts-ignore
  if (window.__app_config__) {
    // @ts-ignore
    return window.__app_config__
  }

  return devModeConfig
}
