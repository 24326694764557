/* eslint-disable @typescript-eslint/no-explicit-any */
import { type CurrentDesigner } from '/@/data/designer/types'
import { useUser } from '/@/data/user'
import { addListItemToCache, deleteListItemFromCache } from '/@/utils/apollo'
import { ApolloCache, type Reference } from '@apollo/client/cache'

interface Options {
  insertMode: 'prepend' | 'append'
}

export function useDesignerCache() {
  const user = useUser()
  const designer = user.current?.designer

  function deleteListItem(
    cache: ApolloCache<any>,
    fieldName: keyof CurrentDesigner,
    itemId: string,
  ) {
    if (!designer) return

    const id = cache.identify(designer)
    return deleteListItemFromCache<CurrentDesigner>(
      id,
      cache,
      fieldName,
      itemId,
    )
  }

  function addListItem(
    cache: ApolloCache<any>,
    fieldName: keyof CurrentDesigner,
    newItemRef: Reference,
    options: Options = { insertMode: 'append' },
  ) {
    if (!designer) return

    const id = cache.identify(designer)
    return addListItemToCache<CurrentDesigner>(
      id,
      cache,
      fieldName,
      newItemRef,
      options,
    )
  }

  function updateField(
    cache: ApolloCache<any>,
    fieldName: keyof CurrentDesigner,
    newValue: unknown | undefined | null,
  ) {
    if (!designer) return
    const id = cache.identify(designer)
    if (!id) return
    cache.modify({
      id,
      fields: {
        [fieldName]() {
          return newValue
        },
      },
    })
  }

  return {
    addListItem,
    deleteListItem,
    updateField,
  }
}
