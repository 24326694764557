import { computed, inject } from 'vue'
import { ApolloClient, gql } from '@apollo/client/core'
import { type NormalizedCacheObject } from '@apollo/client/cache'
import { DefaultApolloClient, useMutation, useQuery } from '/@/core/graphql'
import type {
  ContentDesigner,
  CurrentDesigner,
  DesignerCancelType,
  DesignerPersona,
  DesignerTeamScale,
  DesignerTeamType,
  OnboardingState,
} from '/@/data/designer/types'
import { goalDesignerInfoFragment } from '/@/common/school/api/fragments'
import {
  designerCurrentProjectFragment,
  designerCardDataFragment,
} from './fragments'
import { useCurrentDesigner } from './current'
import { getAccountsDataQuery } from '/@/common/accounts/api'
import { stripeCustomerFragment } from '/@/common/accounts/fragments'
import type { DesignerInterestInput } from '/@/pages/Settings/api'
import type { SpoakSignType } from '/@/common/SpoakSign/types'
import { useDesignerCache } from '/@/data/designer/utils'

interface DesignerByNicknameParams {
  nickname: string
}
interface DesignerByNicknameResponse {
  designerByNickname?: ContentDesigner
}

interface DesignerBrandingInput {
  designerId: string
  logoImageUrl?: string | null
  primaryColor?: string | null
  secondaryColor?: string | null
  backgroundColor?: string | null
}

interface DesignerOnboardingInput {
  designerId: string
  onboardingState: OnboardingState
}

interface DesignerSourceInput {
  source?: string | null
  sourceExtra?: string | null
}

interface DesignerPersonaInput {
  persona?: DesignerPersona | null
}

interface DesignerTeamInfoInput {
  teamType?: DesignerTeamType | null
  teamScale?: DesignerTeamScale | null
}

interface DesignerInterestsInput {
  interests?: DesignerInterestInput[] | null
}

interface DesignerSignInput {
  sign?: SpoakSignType | null
}

interface DesignerCancelInput {
  reason?: string | null
  additionalInfo?: string | null
  cancelType: DesignerCancelType
}

interface DesignerPauseInput {
  months: number
  reason?: string | null
  additionalInfo?: string | null
}

interface CancelResult {
  status: number
  designer?: CurrentDesigner | null
  redirectUrl?: string | null
}

interface InstagramHandleInput {
  instagramHandle: string | null
}

interface DesignerNicknameInput {
  designerId: string
  nickname: string
}

export type DesignerCardData = Pick<
  CurrentDesigner,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'bio'
  | 'hometown'
  | 'nickname'
  | 'imageUrl'
  | 'sign'
> & {
  portfolioProjects: Array<{
    id: string
    imageUrl: string
  }>
  visionBoard?: {
    id: string
    imageUrl: string
  }
}

export function useDesignerApi() {
  const client = inject(
    DefaultApolloClient,
  ) as ApolloClient<NormalizedCacheObject>

  const { designer } = useCurrentDesigner()
  const designerCache = useDesignerCache()

  function fetchCardData({ nickname }: DesignerByNicknameParams) {
    const { result, loading } = useQuery<{
      designerByNickname?: DesignerCardData
    }>(
      gql`
        ${designerCardDataFragment}
        query getDesignerCardData($nickname: String!) {
          designerByNickname(nickname: $nickname) {
            ...DesignerCardDataProps
          }
        }
      `,
      {
        nickname,
      },
    )

    const designer = computed(() => result.value?.designerByNickname)
    return { designer, loading }
  }

  async function fetch({ nickname }: DesignerByNicknameParams) {
    const { data, loading } = await client.query<DesignerByNicknameResponse>({
      query: gql`
        query designerByNickname($input: String!) {
          designerByNickname(nickname: $input) {
            id
            nickname
            website
            branding {
              primaryColor
              secondaryColor
              backgroundColor
              logoImageUrl
            }
          }
        }
      `,
      variables: {
        input: nickname,
      },
    })

    if (loading || !data) return null

    return data.designerByNickname
  }

  const { mutate: addDesignerGoals } = useMutation(gql`
    ${goalDesignerInfoFragment}
    mutation addDesignerGoals(
      $input: DesignerGoalsInput!
      $designerId: String!
    ) {
      designer {
        goal {
          add(input: $input) {
            records {
              id
              goal {
                id
                designerInfo(designerId: $designerId) {
                  ...GoalDesignerInfoProps
                }
              }
            }
          }
        }
      }
    }
  `)

  const { mutate: removeDesignerGoal } = useMutation(gql`
    mutation removeDesignerGoal($input: String!, $designerId: String!) {
      designer {
        goal {
          remove(input: $input) {
            goals {
              id
              designerInfo(designerId: $designerId) {
                id
              }
            }
          }
        }
      }
    }
  `)

  const { mutate: updateBranding } = useMutation(gql`
    mutation updateDesignerBranding($input: DesignerBrandingInput!) {
      designer {
        updateBranding(input: $input) {
          record {
            id
            branding {
              logoImageUrl
              primaryColor
              secondaryColor
              backgroundColor
            }
          }
        }
      }
    }
  `)

  const { mutate: updateOnboarding } = useMutation(gql`
    mutation updateDesignerOnboarding($input: DesignerOnboardingInput!) {
      designer {
        updateOnboarding(input: $input) {
          record {
            id
            onboardingState
          }
        }
      }
    }
  `)

  const { mutate: updateSource } = useMutation(gql`
    mutation updateDesignerSource($input: DesignerSourceInput!) {
      designer {
        updateSource(input: $input) {
          record {
            id
          }
        }
      }
    }
  `)

  const { mutate: updatePersona } = useMutation(gql`
    mutation updateDesignerPersona($input: DesignerPersonaInput!) {
      designer {
        updatePersona(input: $input) {
          record {
            id
            persona
          }
        }
      }
    }
  `)

  const { mutate: updateTeamInfo } = useMutation(gql`
    mutation updateDesignerTeamInfo($input: DesignerTeamInfoInput!) {
      designer {
        updateTeamInfo(input: $input) {
          record {
            id
            teamType
            teamScale
          }
        }
      }
    }
  `)

  const { mutate: updateInterests } = useMutation(gql`
    mutation updateDesignerInterests($input: DesignerInterestsInput!) {
      designer {
        updateInterests(input: $input) {
          record {
            id
            interests {
              id
              interestType
            }
          }
        }
      }
    }
  `)

  const { mutate: updateSign } = useMutation(gql`
    mutation updateDesignerSign($input: SignInput!) {
      designer {
        updateSign(input: $input) {
          record {
            id
            sign
          }
        }
      }
    }
  `)

  const { mutate: updateCurrentProject } = useMutation(gql`
    ${designerCurrentProjectFragment}
    mutation updateDesignerCurrentProject(
      $input: DesignerCurrentProjectInput!
    ) {
      designer {
        updateCurrentProject(input: $input) {
          record {
            id
            currentProject {
              ...DesignerCurrentProjectProps
            }
          }
        }
      }
    }
  `)

  const { mutate: cancel } = useMutation(gql`
    mutation cancel($input: DesignerCancelInput!) {
      designer {
        cancel(input: $input) {
          status
          redirectUrl
          designer {
            id
            status
            accounts {
              id
              account {
                id
                subscriptions {
                  id
                  cancelAt
                  canceledAt
                  cancelType
                }
              }
            }
          }
        }
      }
    }
  `)

  const { mutate: pause } = useMutation(
    gql`
      mutation pause($input: DesignerPauseInput!) {
        designer {
          pause(input: $input) {
            status
            redirectUrl
            designer {
              id
              status
              accounts {
                id
                account {
                  id
                  subscriptions {
                    id
                    cancelAt
                    canceledAt
                    cancelType
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: stopCancelation } = useMutation(
    gql`
      mutation stopCancelation {
        designer {
          stopCancelation {
            recordId
            status
            record {
              id
              accounts {
                id
                account {
                  id
                  subscriptions {
                    id
                    cancelAt
                    canceledAt
                    cancelType
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: getAccountsDataQuery }],
    },
  )

  const { mutate: endFreeTrial } = useMutation<{
    designer: {
      endFreeTrial: {
        status: number
        recordId?: string
      }
    }
  }>(gql`
    mutation endFreeTrial {
      designer {
        endFreeTrial {
          recordId
          status
        }
      }
    }
  `)

  const { mutate: updateInstagramHandle } = useMutation(gql`
    mutation updateInstagramHandle($input: InstagramHandleInput!) {
      designer {
        updateInstagramHandle(input: $input) {
          recordId
          status
          record {
            id
            instagramHandle
          }
        }
      }
    }
  `)

  const { mutate: updateNickname } = useMutation(gql`
    mutation updateDesignerNickname($input: DesignerNicknameInput!) {
      designer {
        updateNickname(input: $input) {
          recordId
          status
          record {
            id
            nickname
          }
        }
      }
    }
  `)

  const { mutate: createStripeCustomer } = useMutation(gql`
    ${stripeCustomerFragment}
    mutation createStripeCustomer($input: StripeCustomerInput!) {
      designer {
        createStripeCustomer(input: $input) {
          recordId
          status
          record {
            id
            stripeCustomerId
            stripeCustomer {
              ...StripeCustomerProps
            }
          }
        }
      }
    }
  `)

  return {
    fetch,
    fetchCardData,
    async addDesignerGoals(designerId: string, goalIds: string[]) {
      await addDesignerGoals({
        input: {
          designerId: designerId,
          goalIds: goalIds,
        },
        designerId,
      })
    },
    async removeDesignerGoal(designerId: string, designerGoalId: string) {
      await removeDesignerGoal({
        input: designerGoalId,
        designerId,
      })
    },
    async updateCurrentProject(projectId?: string) {
      if (
        !designer.value?.id ||
        designer.value?.currentProject?.id === projectId
      )
        return

      await updateCurrentProject({
        input: {
          designerId: designer.value.id,
          currentProjectId: projectId,
        },
      })
    },
    updateBranding(input: DesignerBrandingInput) {
      return updateBranding({ input })
    },
    updateOnboarding(input: DesignerOnboardingInput) {
      return updateOnboarding({ input })
    },
    updateSource(input: DesignerSourceInput) {
      return updateSource({ input })
    },
    updatePersona(input: DesignerPersonaInput) {
      return updatePersona({ input })
    },
    updateTeamInfo(input: DesignerTeamInfoInput) {
      return updateTeamInfo({ input })
    },
    updateInterests(input: DesignerInterestsInput) {
      return updateInterests({ input })
    },
    updateSign(input: DesignerSignInput) {
      return updateSign({ input })
    },
    async cancel(input: DesignerCancelInput) {
      const result = await cancel({ input })
      return result?.data.designer.cancel as CancelResult
    },
    async pause(input: DesignerPauseInput) {
      const result = await pause({ input })
      return result?.data.designer.pause as CancelResult
    },
    stopCancelation() {
      return stopCancelation()
    },
    endFreeTrial() {
      return endFreeTrial(undefined, {
        refetchQueries: [{ query: getAccountsDataQuery }],
      })
    },
    updateInstagramHandle(input: InstagramHandleInput) {
      return updateInstagramHandle({ input })
    },
    updateNickname(input: DesignerNicknameInput) {
      return updateNickname(
        { input },
        {
          update(
            cache,
            {
              data: {
                designer: {
                  updateNickname: {
                    record: { nickname },
                  },
                },
              },
            },
          ) {
            designerCache.updateField(cache, 'nickname', nickname)
          },
        },
      )
    },
    createStripeCustomer(input: {
      emailAddress: string
      firstName: string
      lastName: string
    }) {
      return createStripeCustomer({ input })
    },
  }
}
